<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-date v-model="dateStart"></v-date>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-date v-model="dateEnd"></v-date>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-btn class="mx-3" fab dark color="indigo" @click="Search">
          <v-icon dark>fa fa-search</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-btn class="mx-3" fab dark color="green" @click="Prints">
          <v-icon dark>fa fa-print</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="hd"
          :items="dsbillsale"
          :items-per-page="-1"
          class="elevation-1"
        >
          <template v-slot:[`body.append`]>
            <tr>
              <td colspan="3" class="text-right">รวม</td>
              <td class="text-right">{{ dsbillsale.reduce((x, y) => x + y.LendTotal, 0) }}</td>
              <td class="text-right"></td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      dateEnd: "",
      dateStart: "",
      hd: [
        {
          text: "ระดับสมาชิก",
          align: "left",
          sortable: true,
          value: "UserLevelText.TH",
        },
        {
          text: "ชื่อสมาชิก",
          align: "left",
          sortable: true,
          value: "UserName",
        },
        {
          text: "วันที่ยืม",
          align: "left",
          sortable: true,
          value: "LendDate",
        },
        {
          text: "จำนวนเงิน",
          align: "right",
          sortable: true,
          value: "LendTotal",
          width: 150,
        },
        {
          text: "รายละเอียด",
          align: "right",
          sortable: true,
          value: "LendRemark",
          width: 150,
        },
      ],
      dsbillsale: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.Select(this.dateStart, this.dateEnd);
    });
  },
  methods: {
    Search() {
      this.Select(this.dateStart, this.dateEnd);
    },
    Select(start, end) {
      axios
        .get(`/api/v1/select/Select/Lend?DateStart=${start}&DateEnd=${end}`)
        .then((res) => {
          if (res.status == 200) {
            this.dsbillsale = res.data;
          }
        });
    },
    Prints() {
      if (this.dsbillsale.Array.length <= 0) return;
      axios
        .post("/api/v1/Report/pdf", {
          hdtext: "รายงานยืม",
          hd: this.hd,
          ite: this.dsbillsale.Array,
          fot: [
            { text: "รวม", cols: 4 },
            { text: this.dsbillsale.Total, cols: 1 },
            { text: "", cols: 1 },
          ],
        })
        .then((res) => {
          if (res.status == 200) {
            this.PrintJs({
              printable: res.data.base64,
              type: "pdf",
              base64: true,
            });
          }
        });
    },
  },
};
</script>
<style></style>
